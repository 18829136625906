import { Component, ViewEncapsulation, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar, MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth/auth.service';
import { AllUsersComponent } from '../all-users/all-users.component';
import { Client, ClientInit, ClientService } from '../../../services/client/client.service';
import { AppointmentService } from '../../../services/appointment/appointment.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'pp-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SigninComponent implements OnInit {

  otpReqForm: FormGroup;
  signInForm: FormGroup;
  loading: boolean = false;
  showSignInForm: boolean = false;
  captcha:any;
  captchaLoaded:any = false;
  captchaSvg:any;
  configcaptcharequired:boolean = false;
  minLength: any = 0;
  maxLength: any = 0;
  client: Client = ClientInit;
  
  constructor (
    private fb: FormBuilder,
    private snackbar: MatSnackBar,    
    private router: Router,
    private dialog: MatDialog,
    private authService: AuthService,
    private appointmentService: AppointmentService,
    private _sanitizer: DomSanitizer,
    private clientService: ClientService,
    private dialogRef: MatDialogRef<SigninComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) { 
    this.clientService.getClientDetails().subscribe(res => this.client = res.data || ClientInit);
    this.authService.getAppconfig().subscribe(appconfig => {
      this.configcaptcharequired = appconfig && appconfig.data && appconfig.data.captcharequiredtologin || false;
    }, configerr => {
      this.configcaptcharequired = false;
    });
  }

  ngOnInit() {
    this.client = this.data && this.data.clientdetails || null;
    this.minLength = this.client && this.client.minLengthofphnumber || 5;
    this.maxLength = this.client && this.client.maxLengthofphnumber || 15;
    this.otpReqForm = this.fb.group({
      mobile: [null, [Validators.required, Validators.minLength(this.minLength), Validators.maxLength(this.maxLength)]],
      captcha: [null]
    });
    this.signInForm = this.fb.group({
      otp: [null, Validators.required]
    });
    if (this.data && this.data.mobile) {
      this.otpReqForm.get('mobile').setValue(this.data.mobile);
      this.showSignInForm = true;
    }
    this.Loadcaptcha();
  }

  get f() { return this.otpReqForm.controls; }

  onOtpReqFormSubmit(mobileNo) {
    this.loading = true;
    let captcha = null;
    if (this.captcha) {
        captcha = {
          userEnteredCaptcha: this.otpReqForm.get('captcha').value || null,
          captchaExpression: this.captcha.captchaExpression,
        }
    }
    this.authService.getOtp(mobileNo,captcha).subscribe(res => {
      if (res.allowWithoutOTP) {
        if (res.patient && res.patient._id) {
          this.authService.setAuthToken(res.patient._id, res.patient.externalid, res.patient.orguid);
          this.dialogRef.close(true);
          if (this.data && !this.data.dontNavigateToDashboard) return;
          setTimeout(() => this.router.navigateByUrl(''), 100);
        } else if (res.patients && res.patients.length > 1) {
          this.users(res.patients);
        }
      } else {
        this.snackbar.open('One Time Password (OTP) has been sent to your mobile, please enter the same here to signin', '', {
          panelClass: ['pp-snackbar-info'],
          verticalPosition: 'top',
          horizontalPosition: 'right',
          duration: 5000
        });
        this.showSignInForm = true;
      }
    }, err => {
      let { error } = err;
      this.snackbar.open(err.error.error, '', {
        panelClass: ['pp-snackbar-err'],
        verticalPosition: 'top',
        horizontalPosition: 'right',
        duration: 5000
      });
      this.hideLoader();
      if(error && error.invaildcaptcha){
        this.Loadcaptcha();
      }
    }, () => {
      this.hideLoader();
    });
  }

  onSignInFormSubmit(mobileNo, otp) {
    this.loading = true;
    this.authService.signIn(mobileNo, otp).subscribe(res => {
      if (res.patient && res.patient._id) {
        this.authService.setAuthToken(res.patient._id, res.patient.externalid, res.patient.orguid);
        this.dialogRef.close(true);
        if (this.data && !this.data.dontNavigateToDashboard) return;
        setTimeout(() => this.router.navigateByUrl(''), 100);
      } else if (res.patients && res.patients.length > 1) {
        this.users(res.patients);
      }
    }, err => {
      this.hideLoader();
      this.snackbar.open(err.error.error, '', {
        panelClass: ['pp-snackbar-err'],
        verticalPosition: 'top',
        horizontalPosition: 'right',
        duration: 5000
      });      
    }, () => {
      this.hideLoader();
    });
  }

  Loadcaptcha(){
    this.captchaLoaded = false;
    const noise = this.captcha && this.captcha.noise ? this.captcha.noise - 2 : null;
    this.authService.Loadcaptcha(noise).subscribe(res => {
     this.captcha = res && res.captcha || null;
     this.captchaSvg = this.captcha && this.captcha.captchaSvg || null;
     this.captchaLoaded = true;
    }, err => {
      this.captchaLoaded = false;
    });
  }

  hideLoader() {
    setTimeout(() => this.loading = false, 500);
  }

  users(patients) {
    const dialog = this.dialog.open(AllUsersComponent, {
      panelClass: 'pp-all-users',
      width: '300px',
      disableClose: true,
      data: {
        allUsers: patients
      }
    });
    
    dialog.afterClosed().subscribe(_ => {
      this.dialogRef.close(true);
      if (this.data && !this.data.dontNavigateToDashboard) return;
      setTimeout(() => this.router.navigateByUrl(''), 100);
     });
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  getSVGImage(image:any) {
    return this._sanitizer.bypassSecurityTrustHtml(`${image}`);
  }

  // bookApt() {
  //   const apt = {
  //     orguid: this.data.orguid,
  //     patientuid: this.authService.getUser().refId,
  //     scheduleuid: this.data.scheduleuid,
  //     start: this.data.start,
  //     end: this.data.end,
  //     isteleconsult: !!this.data.isteleconsult,
  //   }
  //   this.appointmentService.createAppointment(apt).subscribe(_ => this.dialogRef.close(true), _ => this.dialogRef.close(false));
  // }

}