import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export interface Client {
  name: string;
  hideLogo: boolean;
  hideFooter: boolean;
  hideVisitTab: boolean;
  hideBillTab: boolean;
  hideRadiologyTab: boolean;
  minLengthofphnumber: Number;
  maxLengthofphnumber: Number;
  hideLabTab: boolean;
  hideUploadTab:boolean;
  theme: {
    primary: string;
  };
  nodepartmentgrouping:boolean;
  onlypatientname: boolean;
}

export const ClientInit: Client = {
  name: 'incarnus',
  hideLogo: false,
  hideFooter: false,
  hideVisitTab: false,
  hideBillTab: false,
  hideRadiologyTab: false,
  hideLabTab: false,
  hideUploadTab: false,
  minLengthofphnumber : null,
  maxLengthofphnumber : null,
  theme: {
    primary: "#3F74B5"
  },
  nodepartmentgrouping: false,
  onlypatientname: false
}

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(private http: HttpClient) { }

  getClientDetails(): Observable<any> {
    return this.http.get<any>(`/api/client/info`);
  }
}
