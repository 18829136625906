import { Component, ViewEncapsulation, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { SigninComponent } from '../shared/signin/signin.component';
import { DoctorService } from '../../services/doctor/doctor.service';
import { Client, ClientInit, ClientService } from '../../services/client/client.service';
import { MatSnackBar, MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { environment } from 'src/environments/environment';
import { SettingsService } from '../../services/settings/settings.service';
import { AuthService } from '../../services/auth/auth.service';

@Component({
  selector: 'pp-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default
})
export class HomeComponent implements OnInit {
  orgForm: FormGroup;
  isScrollable: boolean = false;
  organization: any[] = [];
  departments: any[] = [];
  client: any = {};
  noParentDept: boolean;
  setting = null;

  constructor (
    private fb: FormBuilder,
    private dialog: MatDialog,
    private authService: AuthService,
    private snackbar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,
    private doctorService: DoctorService,
    private clientService: ClientService,
    private settingService: SettingsService
  ) {
    this.clientService.getClientDetails().subscribe(res => this.client = res.data || ClientInit);
    this.settingService.getPortalSetting().subscribe(res => this.setting = res);
  }

  ngOnInit() {
    this.orgForm = this.fb.group({
      location: [null, Validators.required],
      speciality: [null],
      name: [null]
    });
    this.doctorService.getOrganization().subscribe(res => {
      this.organization = res.dropdownlist || [];
      if (this.organization.length) {
        this.orgForm.get('location').setValue(this.organization[0]._id);
        this.loadSpeciality(this.organization[0]._id);
      }
    });
    this.route.queryParams.subscribe(params => {
      if(params && params.sessiontokenid){
        let sessiontokenID:any = params.sessiontokenid;
        this.authService.Verifysessiontoken({'sessiontoken' : sessiontokenID}).subscribe(res => {
          if (res.patient && res.patient._id) {
            this.authService.setAuthToken(res.patient._id, res.patient.externalid, res.patient.orguid);
            setTimeout(() => this.router.navigateByUrl(''), 100);
          }
        }, err => {
          this.snackbar.open(err.error.error, '', {
            panelClass: ['pp-snackbar-err'],
            verticalPosition: 'top',
            horizontalPosition: 'right',
            duration: 5000
          });      
        });
      }
    });
  }

  loadSpeciality(orguid) {
    this.departments = [];
    this.doctorService.getDepartment(orguid).subscribe(res => {
      const data = res.data || [];
      this.noParentDept = false;
      if (!this.client.nodepartmentgrouping) {
        data.forEach(parent => {
          if (!parent.parentdeptuid) {
            this.departments.push(parent);
            this.departments = this.departments.concat(data.filter(child => child.parentdeptuid === parent._id));
          }
        });
      }
      if (data.length && !this.departments.length) {
        this.noParentDept = true;
        this.departments = data;
      }
    });
  }

  signInClick() {
    this.dialog.open(SigninComponent, {
      panelClass: 'pp-sign-in',
      width: '500px',
      disableClose: true,
      data: {
        clientdetails : this.client,
        dontNavigateToDashboard: true
      }
    });
  }
  
  onOrgFormSubmit() {
    if (this.setting && this.setting.hideappointments) return;
    this.router.navigateByUrl('appointment', { skipLocationChange: true, state: { orgForm: this.orgForm.value } });
  }
}