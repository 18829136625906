import { Component, ViewEncapsulation, ViewChild, ElementRef, HostListener, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { DoctorTimeSlotComponent } from '../doctor-time-slot/doctor-time-slot.component';
import { Doctor, DoctorService } from '../../../services/doctor/doctor.service';
import { DoctorInfoComponent } from "../doctor-info/doctor-info.component";
import { Client, ClientInit, ClientService } from '../../../services/client/client.service';

@Component({
  selector: 'pp-doctor-list',
  templateUrl: './doctor-list.component.html',
  styleUrls: ['./doctor-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DoctorListComponent implements OnInit, AfterViewInit {

  @ViewChild('pageEl', { static: false }) pageEl: ElementRef;
  @ViewChild('fixedEl', { static: false }) fixedEl: ElementRef;
  @HostListener('window:resize') onResize = () => this.setScrollStyle();
  page: number = 0;
  viewInit: boolean = false;
  loading: boolean = true;
  scrollStyle: object = {};
  orgForm: FormGroup;
  client: Client = ClientInit;
  doctorListHeight: any = {};
  organization: any[] = [];
  departments: any[] = [];
  doctors: Doctor[] = [];
  orgFormData: any = null;
  appointment: any = null;
  noParentDept: boolean;

  constructor (
    private fb: FormBuilder,
    private dialog: MatDialog,
    private router: Router,
    private doctorService: DoctorService,
    private clientService: ClientService

  ) {
    const routeExtras = this.router.getCurrentNavigation().extras;
    this.clientService.getClientDetails().subscribe(res => this.client = res.data || ClientInit);
    this.orgFormData = routeExtras && routeExtras.state && routeExtras.state.orgForm && routeExtras.state.orgForm || null;
    this.appointment = routeExtras && routeExtras.state && routeExtras.state.appointment && routeExtras.state.appointment || null;
  }

  ngOnInit() {
    this.orgForm = this.fb.group({
      location: [null, Validators.required],
      speciality: [null],
      name: [null]
    });
    this.doctorService.getOrganization().subscribe(res => {
      this.organization = res.dropdownlist || [];      
      if (this.orgFormData) {
        if (this.orgFormData.location && this.organization.length) {
          this.orgForm.get('location').setValue(this.orgFormData.location);
          this.loadSpeciality(this.orgFormData.location);
        }
      } else {        
        if (this.organization.length) {
          this.orgForm.get('location').setValue(this.organization[0]._id);
          this.loadSpeciality(this.organization[0]._id);
        }
      }
    });
    this.orgFormData && this.orgFormData.name && this.orgForm.get('name').setValue(this.orgFormData.name);   
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.setScrollStyle();
      this.orgFormData && this.formSubmit();
    }, 200);
  }

  loadSpeciality(orguid) {
    this.departments = [];
    this.doctors = [];
    this.doctorService.getDepartment(orguid).subscribe(res => {
      const data = res.data || [];
      this.noParentDept = false;
      
      if(!this.client.nodepartmentgrouping) {
        data.forEach(parent => {
          if (!parent.parentdeptuid) {
            this.departments.push(parent);
            this.departments = this.departments.concat(data.filter(child => child.parentdeptuid === parent._id));
          }
        });
      }
      if (data.length && !this.departments.length) {
        this.noParentDept = true;
        this.departments = data;
      }else if(!this.departments.length){
        this.noParentDept = false;
        this.departments = [];
      }

      if (this.orgFormData && this.orgFormData.speciality && this.departments.length) {
        this.orgForm.get('speciality').setValue(this.orgFormData.speciality);
      }
    });
  }

  formSubmit() {    
    this.page = 0;
    this.viewInit = true;
    this.loading = true;
    this.doctors = [];
    this.loadDoctor();
  }

  loadDoctor() {
    this.page += 1;    
    const search = {
      name: this.orgForm.value.name,
      orguid: this.orgForm.value.location || this.orgFormData.location,
      specialtyuid: this.orgForm.value.speciality || (this.orgFormData && this.orgFormData.speciality || null),
      page: this.page
    };
    this.doctorService.getDoctorsList(search).subscribe((res: Doctor[]) => {
      this.doctors = this.doctors.concat(res || []);
      this.hideLoader();
    }, _ => this.hideLoader());
  }

  appointmentClick(doctorId) {
    const dialog = this.dialog.open(DoctorTimeSlotComponent, {
      minWidth: 'calc(100vw - 40px)',
      minHeight: 'calc(100vh - 40px)',
      maxWidth: 'calc(100vw - 40px)',
      maxHeight: 'calc(100vh - 40px)',
      panelClass: 'pp-doctor-time-slot',
      disableClose: true,
      data: { doctorId: doctorId, appointment: this.appointment, currentOrguid: this.orgForm.value.location }
    });
    dialog.afterClosed().subscribe(data => data && this.router.navigateByUrl('dashboard'));
  }

  doctorInformation(doctorId) {
    const dialog = this.dialog.open(DoctorInfoComponent, {
      panelClass: 'pp-doctor-info',
      minWidth: 'calc(100vw - 260px)',
      minHeight: 'calc(100vh - 40px)',
      maxWidth: 'calc(100vw - 260px)',
      maxHeight: 'calc(100vh - 40px)',
      disableClose: true,
      data: { doctorId: doctorId}
    });
    dialog.afterClosed().subscribe(data => data && this.router.navigateByUrl(''));
  }

  setScrollStyle() {
    this.scrollStyle = {
      'height': `calc(${this.pageEl.nativeElement.offsetHeight}px - ${this.fixedEl.nativeElement.offsetHeight + 1}px)`
    };
  }

  hideLoader() {
    setTimeout(() => this.loading = false, 1000);
  }

}